<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm8>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            Grades
            <v-spacer></v-spacer>
            <add-button
              v-if="updateSortable"
              permission="grade-create"
              @action="saveSort"
              class="mr-1"
              icon="swap_vert"
              >Save Sort
            </add-button>
            <add-button
              permission="grade-create"
              @action="
                (form.dialog = true), $refs.form ? $refs.form.reset() : ''
              "
              >New Grade
            </add-button>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            footer-props.items-per-page-options="rowsPerPageItems"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
            ref="sortable"
          >
            <template v-slot:item="{ index, item }">
              <tr class="sortableRow" :key="item.id">
                <td class="sortHandle" style="max-width: 28px;">
                  <v-icon>drag_handle</v-icon>
                </td>
                <!-- <td>{{ index + form.items.meta.from }}</td> -->
                <td class="text-center">
                  <strong>{{ item.rank }}</strong>
                </td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">{{ item.coordinator }}</td>
                <td class="text-right">
                  <view-button
                    label="Sections"
                    permission="section-read"
                    @click.native="viewGrade(item.id)"
                  />
                  <edit-button
                    permission="grade-update"
                    @agree="form.edit(item)"
                  />
                  <delete-button
                    permission="grade-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-flex xs12 sm4>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            <v-spacer />
            <v-btn icon ma-0 small @click="setChart()">
              <v-icon small>autorenew</v-icon>
            </v-btn>
          </v-card-title>
          <apexchart
            v-if="chartData.length"
            type="pie"
            :options="chartOptions"
            :series="chartData"
          />

          <!-- <attendance-chart></attendance-chart> -->
        </v-card>
      </v-flex>

      <v-dialog v-model="form.dialog" persistent max-width="400px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-form
              ref="form"
              v-model="valid"
              :lazy-validation="lazy"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
            >
              <v-text-field
                autocomplete="off"
                label="Grade Name*"
                required
                class="pa-0"
                outlined
                dense
                v-model="form.name"
                name="name"
                :error-messages="form.errors.get('name')"
                :rules="[(v) => !!v || 'Name is required']"
              />
              <v-text-field
                autocomplete="off"
                label="Rank*"
                v-mask="'##'"
                required
                class="pa-0"
                v-model="form.rank"
                name="rank"
                outlined
                dense
                :error-messages="form.errors.get('rank')"
                :rules="[(v) => !!v || 'Rank is required']"
              />
              <v-select
                :items="levels"
                required
                class="pa-0"
                label="Base Level*"
                outlined
                dense
                v-model="form.base_level"
                :rules="[(v) => !!v || 'Name is required']"
                :error-messages="form.errors.get('base_level')"
              />

              <v-autocomplete
                outlined
                dense
                v-model="form.coordinator_id"
                :items="items"
                :loading="isLoading"
                :search-input.sync="search"
                hide-no-data
                item-text="Name"
                item-value="id"
                label="Grade Co ordinator"
                placeholder="Start typing to Search"
                return-object
              ></v-autocomplete>
            </v-form>

            <v-flex xs12>
              <template>
                <div style="color:red;">
                  <v-icon small color="orange">error_outline</v-icon>
                  <span style="color:orange"> Please Note</span>
                  <strong></strong>
                  <p style="line-height: 10px">
                    <small style="color:#666;font-size: 10px;">
                      The rank number provided should be a in hierarchical order
                      <br />
                      according to the grade level.
                    </small>
                  </p>
                </div>
              </template>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" text @click="validate">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import Sortable from "sortablejs";

export default {
  mixins: [Mixins],
  data: () => ({
    valid: true,
    lazy: false,
    isLoading: false,
    updateSortable: false,
    co_ordinator: [],
    model: null,
    entries: [],
    form: new Form(
      {
        name: "",
        rank: "",
        base_level: "",
        coordinator_id: "",
        batch_id: "",
      },
      "/api/grades"
    ),
    pagination: {
      rowsPerPage: 50,
    },
    search: "",
    searchCo: "",
    teacherList: [],
    headers: [
      { text: "", align: "left", value: "id", width: 50, sortable: false },
      {
        text: "Rank",
        align: "center",
        value: "rank",
        width: 80,
        sortable: false,
      },
      { text: "Name", align: "left", value: "name", sortable: false },
      {
        text: "Co-ordinator",
        align: "left",
        value: "coordinator_id",
        sortable: false,
      },
      { text: "Action", align: "right", sortable: false, width: 280 },
    ],
    levels: [
      {
        text: "Basic",
        value: "basic",
      },
      {
        text: "Pre-Basic",
        value: "pre-basic",
      },
      {
        text: "Secondary",
        value: "secondary",
      },
    ],
    chartData: [],
    chartOptions: {
      title: {
        text: "Grade-Wise Students",
        align: "center",
      },
      legend: {
        show: false,
        floating: true,
      },
      labels: [],
    },
    rankRule: [
      (v) => !!v || "Rank is required",
      (v) => typeof v !== "number" || "Rank must be valid",
    ],
  }),

  computed: {
    ...mapState(["batch"]),
    items() {
      return this.entries.map((entry) => {
        return Object.assign({}, entry, { Name: entry.name });
      });
    },
  },

  mounted() {
    const _self = this;
    let table = this.$refs.sortable.$el.querySelector("tbody");
    Sortable.create(table, {
      draggable: ".sortableRow",
      handle: ".sortHandle",
      onEnd: _self.sorted,
    });
  },

  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      this.get();
    },
    searchCo: function(text) {
      if (!text) return;
      if (text.length < 2) {
        this.isLoading = false;
        return;
      }
      if (this.isLoading) return;
      this.searchTeacher(text);
    },
    search(val) {
      if (!val) return;
      if (this.isLoading) return;

      this.isLoading = true;
      this.$rest
        .get("api/user-search/slim?type=teacher&search=" + val)
        .then(({ data }) => {
          this.entries = data.data;
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
        this.setChart(data.data);
      });
    },

    searchTeacher(v) {
      this.isLoading = true;
      this.$rest.get("/api/user/slim?search=" + v).then(({ data }) => {
        this.isLoading = false;
        this.teacherList = data.data.map((teach) => {
          return { value: teach.id, text: teach.name };
        });
      });
    },
    save() {
      this.form.batch_id = this.batch.id;
      if (this.form.coordinator_id && Object.keys(this.form.coordinator_id))
        this.form.coordinator_id = this.form.coordinator_id.id;

      this.form.store();
    },

    setChart(data) {
      if (data == null) {
        data = this.form.items.data;
      }
      this.chartOptions.labels = data.map((item) => {
        return item.name;
      });
      this.chartData = data.map((item) => {
        return item.student_count;
      });
    },

    viewGrade(id) {
      this.$router.push({
        name: "grades-detail",
        params: {
          gradeId: id,
        },
      });
    },

    sorted({ newIndex, oldIndex }) {
      const rowSelected = this.form.items.data.splice(oldIndex, 1)[0];
      this.form.items.data.splice(newIndex, 0, rowSelected);
      this.updateSortable = true;
    },

    saveSort() {
      let sortedData = this.form.items.data.map((item, index) => {
        return {
          id: item.id,
          rank: ++index,
        };
      });

      // TODO: ajax request here...
      this.updateSortable = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
